import React from 'react';

const LiveMarker = props => {
  const { status } = props;

  return (
    <div className={`pulseIcon pulseIcon${status || 'Base'}`} />
  );
};

export default LiveMarker;
