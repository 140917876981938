const getApiBaseUrl = () => {
    if (process.env.REACT_APP_ENV === 'development') {
        return "https://api.autosled-dev.com";
    } else if (process.env.REACT_APP_ENV === 'testing') {
        return "https://api.autosled-testing.com";
    } else {
        return "https://api.autosled.com";
    }
}

export const fetchLoads = async (shipmentId) => {
    try {
        const res = await fetch(`${getApiBaseUrl()}/api/Dealer/Loads/Shipments/${shipmentId}/Track`);
        const data = await res.json();
        return data;
    } catch(err) {
        console.error('Error fetching data', err);
        return [];
    }
}